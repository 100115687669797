import React from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/layout/Layout';
import SEO from '../components/meta/SEO';
import HeroSection from '../components/organisms/HeroSection';

const NotFoundPage = () => {
  const browser = typeof window !== 'undefined' && window;
  if (!browser) return <></>;
  return (
    <Layout>
      <SEO
        pathname="/404/"
        type="website"
        title="Uh Oh! 404"
        description="404"
      />

      <HeroSection
        heading="Uh oh!"
        subheading="Looks like that page doesn't exist."
        ctaLabel="Back to Homepage"
        ctaOnClick={() => navigate('/')}
        showZipForm={false}
      />
    </Layout>
  );
};

export default NotFoundPage;
