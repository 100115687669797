import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import cuid from 'cuid';

const Input = ({
  className,
  error,
  maxLength,
  name,
  onChange,
  placeholder,
  required,
  type,
  value,
}) => {
  const inputId = useMemo(() => `TextInput-${cuid()}`, []);

  return (
    <input
      type={type}
      name={name}
      className={classNames('input', { 'input--error': error }, className)}
      id={inputId}
      value={value}
      maxLength={maxLength}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
      required={required}
    />
  );
};

Input.defaultProps = {
  className: '',
  error: false,
  maxLength: null,
  placeholder: '',
  required: false,
  type: 'text',
};

Input.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default Input;
